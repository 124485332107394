<template>
  <div class="handleList">
    <div class="main">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
        :commonOperaParams="commonOperaParams"
      >
        <div
          class="li"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="li-t">
            <div class="li-tl">{{ item.orderTitle }}</div>
            <div :class="['li-tr', 'te' + item.orderStatus]">
              {{ statusTypeMap[item.orderStatus] }}
            </div>
          </div>
          <div class="li-b">
            <div class="li-bl"><img :src="item.imageUrl" alt="" /></div>
            <div class="li-br">
              <div class="h4">{{ item.orderDisc }}</div>
              <div class="date">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { statusTypeMap } from "../map";
import { getOrderInfoPageListUrl } from "./api";
export default {
  name: "handleList",
  data() {
    return {
      signParmas: {},
      statusTypeMap,
      finishedText: "没有更多了",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        type: 1,
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
    };
  },
  mounted() {},
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    role() {
      return this.$store.state.userId;
    },
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "lawEnforcement-handleDetail",
        query: {
          orderId: item.orderId,
        },
      });
    },
    getList() {
      let params = {
        orderStatus: this.$route.query.orderStatus,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        role: this.role,
      };
      this.$axios.post(`${getOrderInfoPageListUrl}`, params).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    onLoad() {
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.handleList {
  min-height: 100%;
  background: #fafafaff;
  box-sizing: border-box;
  .main {
    padding: 20px 30px 0;
    .li {
      height: 268px;
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      padding: 0 34px;
      box-sizing: border-box;
      .li-t {
        padding: 36px 0 18px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        .li-tl {
          font-weight: 700;
          font-size: 32px;
          color: #333333;
          line-height: 44px;
          position: relative;
          padding-left: 34px;
          &::before {
            content: "";
            width: 12px;
            height: 12px;
            background: #3d7bfa;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .li-tr {
          width: 120px;
          height: 40px;
          background: rgba(255, 206, 111, 0.2);
          border-radius: 4px;
          font-weight: 700;
          font-size: 24px;
          color: #ffce6f;
          line-height: 40px;
          text-align: center;
        }
        .te0 {
        }
        .te1 {
          width: 120px;
          height: 40px;
          background: rgba(61, 123, 250, 0.2);
          border-radius: 4px;
          font-weight: 700;
          font-size: 24px;
          color: #3d7bfa;
          line-height: 40px;
          text-align: center;
        }
        .te2 {
          width: 120px;
          height: 40px;
          background: rgba(61, 123, 250, 0.2);
          border-radius: 4px;
          font-weight: 700;
          font-size: 24px;
          color: #3d7bfa;
          line-height: 40px;
          text-align: center;
        }
      }
      .li-b {
        margin-top: 16px;
        display: flex;
        align-items: center;
        .li-bl {
          width: 210px;
          height: 118px;
          margin-right: 14px;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .li-br {
          .h4 {
            font-weight: 700;
            font-size: 26px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 36px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .date {
            font-weight: 400;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
            line-height: 34px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
